
import LinkedInLoginCard from '@/components/linked-in/LinkedInLoginCard.vue';
import { LinkedInPlatformData, PublicBot, SupportedPlatform } from 'ignite360-core';
import _merge from 'lodash.merge';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'public-social-login-linked-in',
  components: { LinkedInLoginCard },
})
export default class PublicSocialLoginLinkedIn extends Vue {
  @Prop({ required: true, type: Object })
  readonly bot!: PublicBot;

  onConnect(data: Partial<LinkedInPlatformData>) {
    const newBot: PublicBot = _merge({}, this.bot, {
      platformData: {
        [SupportedPlatform.LinkedIn]: data,
      },
    });
    this.$emit('update:bot', newBot);
  }

  onDisconnect() {
    const newBot: PublicBot = _merge({}, this.bot, {
      platformData: {
        [SupportedPlatform.LinkedIn]: {
          _id: '',
          type: '',
        },
      },
    });
    this.$emit('update:bot', newBot);
  }
}
